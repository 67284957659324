export const hdFonts = {
    header: {
        fontFamily: "PaytoneOne-Regular"
    },
    text: {
        fontFamily: "Muli",
        fontWeight: 200
    },
    textLight: {
        fontFamily: "Muli",
        fontWeight: 100
    },
    textItalic: {
        fontFamily: "Muli",
        fontStyle: "italic"
    },
    textBold: {
        fontFamily: "Muli",
        fontWeight: 800
    },
    textBoldItalic: {
        fontFamily: "Muli",
        fontWeight: "bold",
        fontStyle: "italic"
    },
    textSemiBold: {
        fontFamily: "Muli",
        fontWeight: 600
    },
    title: {
        fontFamily: "Nunito",
        fontWeight: 900
    },
    mono: {
        fontFamily: "Roboto Mono, monospace",
        fontWeight: 400
    },
    light: {
        fontFamily: "Lato",
        fontWeight: 300
    },
    lightItalic: {
        fontFamily: "Lato",
        fontWeight: 300,
        fontStyle: "italic"
    },
    extraThin: {
        fontFamily: "Lato",
        fontWeight: 200
    },
    regular: {
        fontFamily: "Nunito",
        fontWeight: 400
    },
    bold: {
        fontFamily: "Nunito",
        fontWeight: 700
    },
    semiBold: {
        fontFamily: "Nunito",
        fontWeight: 600
    },
};
