import firebase from "firebase";
// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/functions";
import "firebase/messaging";

import config from "./config";

export function bootstrapFirebase() {
    const firebaseConfig = {
        apiKey: config.firebase.apiKey,
        authDomain: config.firebase.authDomain,
        databaseURL: config.firebase.databaseURL,
        projectId: config.firebase.projectId,
        storageBucket: config.firebase.storageBucket,
        messagingSenderId: config.firebase.messagingSenderId,
        appId: config.firebase.appId,
        measurementId: config.firebase.measurementId
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
}
