import React from "react";
import "animate.css/animate.css";
import {hdColors} from "./config/colors";


export default function HomePage() {
    return (
        <div className="App" style={{backgroundColor: hdColors.secondaryLightest}}>
            <header className="App-header" style={{flexDirection: "row"}}>
                <div style={{flex: 1, alignItems: "center"}}>
                    <i className="fas fa-credit-card-alt" style={{fontSize: 72, color: hdColors.secondaryDarkest}}/>
                </div>
            </header>
        </div>
    );
}
