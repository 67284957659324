import React from "react";
import "animate.css/animate.css";
import View from "./components/View";
import Text from "./components/Text";
import {hdFonts} from "./config/fonts";
import {hdColors} from "./config/colors";


export default function AddPaymentMethodSuccessPage(props) {
    return (
        <View style={{flex: 1, alignItems: "center", backgroundColor: hdColors.secondaryDark}}>
            <Text style={{...hdFonts.regular, fontSize: 24, color: "white"}}>¡Éxito!</Text>
        </View>
    );
}
