import axios from "axios";
import config from "./config";
import firebase from "firebase";

const billingServiceHost = config.billingServiceHost;
// const billingServiceHost = "http://192.168.100.4:3005";

const {auth} = firebase;

export default class BillingService {
    static http = axios.create({
        baseURL: billingServiceHost,
        timeout: 60000,
    });

    static async completePayoutAccount() {
        const {currentUser} = auth();

        const userToken = await currentUser.getIdToken();

        return BillingService.http.put(`/payout-accounts/_complete`, {}, {
            headers: {
                "Authorization": userToken
            }
        });
    }

    static async createPaymentMethod(paymentMethodID, paymentSessionToken) {
        // const {currentUser} = auth();
        //
        // const userToken = await currentUser.getIdToken();

        return BillingService.http.post(`/payment-methods`, {paymentMethodID}, {
            headers: {
                "Authorization": paymentSessionToken
            }
        });
    }
}
