import React from "react";
import "animate.css/animate.css";
import {useLocation} from "@reach/router";
import firebase from "firebase";

import Text from "../../components/Text";
import View from "../../components/View";
import {hdColors} from "../../config/colors";
import {hdFonts} from "../../config/fonts";
import {logMessage} from "../../sessions/chatter";

const {auth} = firebase;

export default function PayoutAccountOnBoardingStartPage(props) {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const userSessionToken = urlParams.get("userSessionToken");
    const onboardingURL = urlParams.get("onboardingURL");
    const chatterSessionID = urlParams.get("chatterSessionID");

    const goToOnboardingURL = () => window.location.assign(onboardingURL);

    React.useEffect(() => {
        localStorage.setItem("userSessionToken", userSessionToken);
        localStorage.setItem("chatterSessionID", chatterSessionID);

        logMessage(`started with userSessionToken ${userSessionToken}`);

        auth().signInWithCustomToken(userSessionToken)
            .then(goToOnboardingURL)
            .catch(error => logMessage(`error signing in with custom token ${JSON.stringify(error)}`));
    }, []);

    return (
        <View style={{flex: 1, height: "100vh", backgroundColor: hdColors.secondaryDarkest, justifyContent: "center", alignItems: "center"}}>
            <Text style={{...hdFonts.regular, fontSize: 16, color: "white"}}>Conectando con&nbsp;<Text style={{...hdFonts.title}}>Stripe</Text>...</Text>
        </View>
    );
}
