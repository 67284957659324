import React from "react";
import "animate.css/animate.css";
import {useLocation} from "@reach/router";
import firebase from "firebase";

import Text from "../../components/Text";
import View from "../../components/View";
import {hdColors} from "../../config/colors";
import {hdFonts} from "../../config/fonts";

const {auth, firestore, functions} = firebase;


export default function PayoutAccountOnBoardingReauthPage(props) {
    const userSessionToken = localStorage.getItem("userSessionToken");
    const stripePayoutAccountID = localStorage.getItem("stripePayoutAccountID");

    const getPayoutAccountOnBoardingURL = async () => {
        const {currentUser} = auth();

        const payoutAccountsQuerySnapshot = await firestore()
            .collection(`users/${currentUser.uid}/settings/billing/payoutAccounts`)
            .where("providerID", "==", stripePayoutAccountID)
            .limit(1)
            .get();

        if (payoutAccountsQuerySnapshot.empty) {
            throw new Error(`no payment account found for user ${currentUser.uid} with stripePayoutAccountID ${stripePayoutAccountID}`);
        }

        const payoutAccountSnapshot = payoutAccountsQuerySnapshot.docs[0];
        const payoutAccountID = payoutAccountSnapshot.id;

        const getPayoutAccountOnBoardingURLCallable = functions().httpsCallable("getPayoutAccountOnboardingURL");
        const response = await getPayoutAccountOnBoardingURLCallable({payoutAccountID});

        if (!response?.data) {
            throw new Error(`got bad response: ${JSON.stringify(response)}`)
        }

        return response.data.url;
    }

    const doGetPayoutAccountOnBoardingURL = () => {
        getPayoutAccountOnBoardingURL()
            .then(onboardingURL => window.location.assign(onboardingURL))
            .catch(error => console.warn("error getting onboarding URL", error));
    }

    React.useEffect(() => {
        auth().signInWithCustomToken(userSessionToken)
            .then(() => localStorage.setItem("userSessionToken", userSessionToken))
            .then(doGetPayoutAccountOnBoardingURL)
            .catch(error => console.warn(`error signing in with custom token`, error));
    }, []);

    return (
        <View style={{flex: 1, height: "100vh", backgroundColor: hdColors.secondaryDark, justifyContent: "center", alignItems: "center"}}>
            <Text style={{...hdFonts.extraThin, fontSize: 16, color: "white"}}>Reinicializando...</Text>
        </View>
    );
}
