import React from "react";
import View from "./View";

export default function PaymentMethodCardTypeIcon(props) {
    const {type} = props;

    let size = props.size || 42;

    const getIconSource = () => {
        const iconStyle = props.light ? "light" : "dark";

        const doGetSource = iconFilename => `/icons/payment-methods/${iconStyle}/${iconFilename}`;

        switch(type) {
            case "mastercard":
                return doGetSource("mastercard.png");
            case "visa":
                return doGetSource("visa.png");
            case "amex":
                return doGetSource("amex.png");
            case "discover":
                return doGetSource("discover.png");
            default:
                return null;
        }
    }

    const iconSource = getIconSource();

    if (!iconSource) {
        return null;
    }

    return (
        <View style={{alignItems: "center"}}>
            <img src={iconSource} style={{width: size}}/>
        </View>
    )
}
