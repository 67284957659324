import React from "react";
import {navigate, Router, useLocation} from "@reach/router";
import firebase from "firebase";
import HomePage from "./HomePage";
import AddPaymentMethodPage from "./AddPaymentMethodPage";
import PayoutAccountOnBoardingStartPage from "./routes/onboarding/PayoutAccountOnboardingStartPage";
import PayoutAccountOnboardingReturnPage from "./routes/onboarding/PayoutAccountOnboardingReturnPage";

import PayoutAccountOnboardingReauthPage from "./routes/onboarding/PayoutAccountOnboardingReauthPage";
import AddPaymentMethodSuccessPage from "./AddPaymentMethodSuccessPage";

export default function HomeRouter() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const userSessionToken = urlParams.get("userToken");

    React.useEffect(() => {
        // auth().signInWithCustomToken(userSessionToken)
        //     .then(() => localStorage.setItem("userSessionToken", userSessionToken))
        //     .catch(error => console.warn(`error signing in with custom token`, error));

        return () => {

        }
    }, []);

    return (
        <Router>
            <HomePage path={"/"} />
            <AddPaymentMethodPage path={"/payment-methods"} />
            <AddPaymentMethodSuccessPage path={"/add-payment-method-success"} />
            <PayoutAccountOnBoardingStartPage path={"/onboarding"}/>
            <PayoutAccountOnboardingReauthPage path={"/onboarding/reauth"}/>
            <PayoutAccountOnboardingReturnPage path={"/onboarding/return"}/>
        </Router>
    )
}
