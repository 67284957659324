export default {
    "billingServiceHost": "https://billing-service-pusuheofiq-uc.a.run.app",
	"firebase": {
		"apiKey": "AIzaSyAMOQYXSgQHaazbAAAMEoFO7xY60DnNk-M",
        "authDomain": "hellodoctor-production-303000.firebaseapp.com",
        "projectId": "hellodoctor-production-303000",
        "storageBucket": "hellodoctor-production-303000.appspot.com",
        "messagingSenderId": "24316860364",
        "messagingVapidKey": "BFs9UlOyVYMMjGOmUif09QBr33zU2pIMBldjG4GUYiWbGi5uod5Y7oQAJZSvFt31MnivSFARfLIpZGLTkzz65WY",
        "appId": "1:24316860364:web:fd23b96ef53efe07772f69",
        "measurementId": "G-SWRBFNLN36"
	},
    "stripe": {
        "publishableKey": "pk_live_51HFNyaC77Mea1H7yootKpNe7QIBMN5BVoq3JxZr2Ic6eOVZnsNE4wt0gclNNdQygNa4M3GGxWjflt2ZXEsth74ud00lljB9Z0d",
    }
}
