import firebase from "firebase";

const {auth, firestore} = firebase;

export function logMessage(message) {
    console.debug(message);
}

export function setChatterSessionID(chatterSessionID) {
    localStorage.setItem("chatterSessionID", chatterSessionID);
}

export async function sendChatterSessionMessage(chatterMessage) {
    logMessage(`sending chatter session message ${JSON.stringify(chatterMessage)}`).catch(console.warn);

    const doSendChatterSessionMessage = () => {
        const {currentUser} = auth();
        logMessage(`4. LOGGED IN AS ${JSON.stringify(currentUser)}`);
        const chatterSessionID = localStorage.getItem("chatterSessionID");

        if (!chatterSessionID) {
            logMessage("no chatter session id found in local storage; can't send message");
            return;
        }

        chatterMessage.time = new Date();
        logMessage(`5. LOGGED IN AS ${JSON.stringify(currentUser)}`);
        logMessage(`users/${currentUser.uid}/chatter/${chatterSessionID}/messages`);
        firestore()
            .collection(`users/${currentUser.uid}/chatter/${chatterSessionID}/messages`)
            .add(chatterMessage)
            .catch(error => logMessage(`error adding chatter message ${JSON.stringify(error)}`));
    }

    const userSessionToken = localStorage.getItem("userSessionToken");
    logMessage(`userSessionToken ${userSessionToken}`);
    await auth().signInWithCustomToken(userSessionToken)
        .then(doSendChatterSessionMessage)
        .catch(error => logMessage(`error signing in to send chat session message ${JSON.stringify(error)}`));
}

export async function endChatterSession() {
    await sendChatterSessionMessage({status: "chatterSessionEnded"});

    localStorage.removeItem("chatterSessionID");
}
