import React from "react";

export default function TouchableOpacity(props) {
    const onClick = props.disabled ? null : props.onClick;

    return (
        <div onClick={onClick} style={{display: "flex", flexDirection: "column", cursor: "pointer", ...props.style}} className={props.className}>
            {props.children}
        </div>
    )
}
