import React from "react";
import "animate.css/animate.css";
import {navigate, useLocation} from "@reach/router";
import firebase from "firebase";

import Text from "../../components/Text";
import View from "../../components/View";
import {hdColors} from "../../config/colors";
import {hdFonts} from "../../config/fonts";
import {logMessage, sendChatterSessionMessage} from "../../sessions/chatter";
import BillingService from "../../../service";

const {auth, functions} = firebase;

export default function PayoutAccountOnBoardingReturnPage(props) {
    const userSessionToken = localStorage.getItem("userSessionToken");

    const doCompletePayoutAccountOnboarding = () => auth().signInWithCustomToken(userSessionToken)
        .then(async () => {
            await BillingService.completePayoutAccount()

            const {currentUser} = auth();

            logMessage(`"3. LOGGED IN AS ${JSON.stringify(currentUser)}`);
            await sendChatterSessionMessage({status: "finishedAddingPayoutAccount"});

            localStorage.removeItem("stripePayoutAccountID");
            localStorage.removeItem("userSessionToken");
        })

    React.useEffect(() => {
        logMessage(`returned with userSessionToken ${userSessionToken}`);

        window.ReactNativeWebView.postMessage("complete");
    })

    return (
        <View style={{flex: 1, height: "100vh", backgroundColor: hdColors.secondaryDark, justifyContent: "center", alignItems: "center"}}>
            <Text style={{...hdFonts.extraThin, fontSize: 16, color: "white"}}>Finalizando...</Text>
        </View>
    );
}
