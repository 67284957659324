import React from "react";
import "animate.css/animate.css";
import "@stripe/stripe-js";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    ElementsConsumer
} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useLocation} from "@reach/router";

import config from "../config";
import View from "./components/View";
import _ from "lodash";
import PaymentMethodCardTypeIcon from "./components/PaymentMethodCardTypeIcon";
import {alpha, hdColors} from "./config/colors";
import BillingService from "../service";
import {hdFonts} from "./config/fonts";
import Text from "./components/Text";
import TouchableOpacity from "./components/TouchableOpacity";
import Icon from "@mdi/react";
import {mdiCheck, mdiClose} from "@mdi/js";
import HideableView from "./components/HideableView";

const stripePromise = loadStripe(config.stripe.publishableKey);

function CreditCardInputView(props) {
    const expirationDateInputRef = React.useRef({});
    const cvcInputRef = React.useRef({});

    const [focusedInput, setFocusedInput] = React.useState(null);
    const [cardNumberData, setCardNumberData] = React.useState("");
    const [expirationDateData, setExpirationDateData] = React.useState(null);
    const [cvcData, setCVCData] = React.useState("");
    const [isCardValid, setIsCardValid] = React.useState(false);
    const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = React.useState(false);

    const {stripe, elements, userSessionToken} = props;

    if (!stripe || !elements) {
        console.info("stripe and/or elements not yet ready");
        return null;
    }

    console.info(`stripe ready!`);

    const doSetCardNumberData = cardNumberData => {
        if (cardNumberData.error) {
            console.error(`error setting card number data: ${JSON.stringify(cardNumberData.error.message)}`);
        } else {
            console.log(`[cardNumberData] changeEvent: ${JSON.stringify(cardNumberData)}`);

            setCardNumberData(cardNumberData);
            checkCardData(cardNumberData, expirationDateData, cvcData);
        }
    }

    const doSetExpirationDateData = expirationDateData => {
        if (expirationDateData.error) {
            console.error(`error setting expiration date data: ${JSON.stringify(expirationDateData.error.message)}`);
        } else {
            console.log(`[expirationDateDate] changeEvent: ${JSON.stringify(expirationDateData)}`);

            setExpirationDateData(expirationDateData);
            checkCardData(cardNumberData, expirationDateData, cvcData);
        }
    }

    const doSetCVCData = cvcData => {
        if (cvcData.error) {
            console.error(`error setting cvc data: ${JSON.stringify(cvcData.error.message)}`);

        } else {
            console.log(`[cvcData] changeEvent: ${JSON.stringify(cvcData)}`);

            setCVCData(cvcData);
            checkCardData(cardNumberData, expirationDateData, cvcData);
        }
    }

    const checkCardData = (cardNumberData, expirationDateData, cvcData) => {
        const isCardNumberValid = cardNumberData?.complete === true;
        const isExpirationDateValid = expirationDateData?.complete === true;
        const isCVCValid = cvcData?.complete === true;

        setIsCardValid(isCardNumberValid && isExpirationDateValid && isCVCValid);

        if (focusedInput === "cardNumber" && isCardNumberValid) {
            doFocusExpirationDate();
        } else if (focusedInput === "expirationDate" && _.size(expirationDateData) === 4) {
            doFocusCVC();
        }
    }

    const doFocusExpirationDate = () => {
        expirationDateInputRef.current.focus();
        setFocusedInput("expirationDate");
    }

    const doFocusCVC = () => {
        cvcInputRef.current.focus();
        setFocusedInput("cvc");
    }

    const CreditCardTypeView = () => {
        let cardIcon, brandLabel;

        switch (cardNumberData?.brand) {
            case "amex":
                cardIcon = "cc-amex";
                brandLabel = "American Express";
                break;
            case "visa":
                cardIcon = "cc-visa";
                brandLabel = "Visa";
                break;
            case "mastercard":
                cardIcon = "cc-mastercard";
                brandLabel = "Mastercard";
                break;
            default:
                cardIcon = "credit-card";
                brandLabel = "Unsupported";
        }

        return (
            <View style={{boxShadow: "0 3px 6px rgba(0,0,0,0.16)"}}>
                <PaymentMethodCardTypeIcon type={cardNumberData?.brand} size={64}/>
            </View>
        )
    }

    const cardNumberBorderColor = focusedInput === "cardNumber" ? hdColors.good : "transparent";
    const cvcBorderColor = focusedInput === "cvc" ? hdColors.good : "transparent";

    const doPostMessage = message => {
        if (!window.ReactNativeWebView) {
            console.warn(`[no react native webview found] ${message}`)
            return;
        }

        window.ReactNativeWebView.postMessage(message);
    }
    const doCancelCreateCardPaymentMethod = async () => {
        doPostMessage("cancelCreate");
    };

    const doCreateCardPaymentMethod = async () => {
        doPostMessage("savingCard");

        // setIsCreatingPaymentMethod(true);

        createCardPaymentMethod()
            .then(() => {
                doPostMessage("saveSuccess");
            })
            .catch(error => {
                doPostMessage("saveError");
                console.warn(`error creating card payment method: ${JSON.stringify(error)}`);
            })
            .finally(() => setIsCreatingPaymentMethod(false));
    };

    const createCardPaymentMethod = async () => {
        const cardNumber = elements.getElement(CardNumberElement);

        console.debug("creating");
        let createCardTokenResult;
        try {
            createCardTokenResult = await stripe.createToken(cardNumber).catch(error => console.error(`caught error: ${error}`));
            console.debug(`payload: ${JSON.stringify(createCardTokenResult)}`);
        } catch(error) {
            console.warn(`error: ${JSON.stringify(error)}`);
            return;
        }

        if (createCardTokenResult.error) {
            console.warn(`createCardTokenResult.error: ${JSON.stringify(createCardTokenResult.error)}`);
            throw createCardTokenResult.error;
        }

        const createPaymentMethodResult = await stripe.createPaymentMethod({
            type: "card",
            card: {token: createCardTokenResult.token.id}
        });

        if (createPaymentMethodResult.error) {
            throw createPaymentMethodResult.error;
        }

        const {paymentMethod} = createPaymentMethodResult;

        await BillingService.createPaymentMethod(paymentMethod.id, userSessionToken);
    }

    const cardElementOptions = {
        style: {
            base: {
                color: hdColors.secondaryDarkest,
                ...hdFonts.mono,
                fontSmoothing: "antialiased",
                fontSize: "20px",
                "::placeholder": {
                    color: alpha(hdColors.secondaryDarkest, 0.2)
                }
            },
            invalid: {
                color: hdColors.negativeLight,
                iconColor: hdColors.negativeLight
            }
        }
    };

    return (
        <View className={"card-input-view-container"}>
            <View className={"card-input-view"}>
                <View style={{flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                    <View style={{alignItems: "center", marginBottom: 6}}>
                        <img src={"/icons/credit-card-chip.png"} style={{width: 42}}/>
                    </View>
                    <CreditCardTypeView/>
                </View>
                <View style={{flexDirection: "row", marginTop: 12}}>
                    <View style={{flex: 1}}>
                        <View style={{flexDirection: "row", alignItems: "center", borderColor: cardNumberBorderColor}} className={"card-input"}>
                            <View style={{flex: 1}}>
                                <CardNumberElement
                                    id={"card-number-element"}
                                    onChange={doSetCardNumberData}
                                    options={cardElementOptions}
                                />
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 12}}>
                    <View style={{width: 128}}>
                        <View className={"card-input"}>
                            <CardExpiryElement
                                id={"card-expiry-element"}
                                onChange={doSetExpirationDateData}
                                options={cardElementOptions}
                            />
                        </View>
                        <Text style={{...hdFonts.semiBold, fontSize: 12, color: "white", marginLeft: 6}}>VALIDA HASTA</Text>
                    </View>
                    <View style={{width: 96}}>
                        <View style={{borderColor: cvcBorderColor, borderWidth: 1, borderStyle: "solid"}} className={"card-input"}>
                            <CardCvcElement
                                id={"card-cvc-element"}
                                onChange={doSetCVCData}
                                options={cardElementOptions}
                                style={{flex: 1}}
                            />
                        </View>
                        <Text style={{...hdFonts.semiBold, fontSize: 12, color: "white", marginLeft: 6}}>CVC</Text>
                    </View>
                </View>
                <View style={{alignItems: "center", marginTop: 6}}>
                    <img src={"/icons/stripe-badge-white.png"} style={{width: 128}}/>
                </View>
            </View>
            <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 12}}>
                <TouchableOpacity
                    className={"button"}
                    onClick={doCancelCreateCardPaymentMethod}
                    style={{
                        flex: 1,
                        margin: 6,
                        padding: 6,
                        borderRadius: 4,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: hdColors.negativeLight,
                        opacity: 1,
                        shadowOpacity: 0.4,
                        shadowRadius: 1,
                        shadowOffset: {height: 0}
                    }}>
                    <Icon path={mdiClose} color={"white"} style={{height: 24, marginRight: 12}}/>
                    <Text style={{...hdFonts.regular, fontSize: 18, color: "white"}}>Cancelar</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    className={"button"}
                    disabled={!isCardValid}
                    onClick={doCreateCardPaymentMethod}
                    style={{
                        flex: 1,
                        margin: 6,
                        padding: 6,
                        borderRadius: 4,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: hdColors.secondaryDark,
                        opacity: isCardValid ? 1 : 0.4,
                        shadowOpacity: 0.4,
                        shadowRadius: 1,
                        shadowOffset: {height: 0}
                    }}>
                    <Icon path={mdiCheck} color={"white"} style={{height: 24, marginRight: 12}}/>
                    <Text style={{...hdFonts.regular, fontSize: 18, color: "white"}}>Guardar</Text>
                </TouchableOpacity>
            </View>
            <HideableView isHidden={!isCreatingPaymentMethod} style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                maxHeight: isCreatingPaymentMethod ? "100%" : 0,
                backgroundColor: hdColors.secondaryDark,
                justifyContent: "center",
                alignItems: "center"}}>
                <Text style={{...hdFonts.extraThin, fontSize: 24, color: "white"}}>Guardando...</Text>
            </HideableView>
        </View>
    )
};


export default function AddPaymentMethodPage(props) {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const userSessionToken = urlParams.get("userToken");

    const InjectedCreditCardInputView = () => (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <CreditCardInputView elements={elements} stripe={stripe} userSessionToken={userSessionToken} />
            )}
        </ElementsConsumer>
    )

    return (
        <View style={{backgroundColor: "white"}}>
            <Elements stripe={stripePromise}>
                <InjectedCreditCardInputView/>
            </Elements>
        </View>
    );
}
