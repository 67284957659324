import React from "react";
import {hdColors} from "../config/colors";
import View from "./View";

export default function HideableView(props) {
    const {isHidden} = props;

    if (isHidden) {
        return null;
    }

    return (
        <View style={props.style}>
            {props.children}
        </View>
    )
}
